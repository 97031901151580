import { Button, ButtonProps, LinkTypeMap, Link as MuiLink } from "@mui/material";
import { ElementType } from "react";

export type LinkPropsBase = {
    url: string | undefined | null
    label: string | undefined | null
    relativeLinkComponent?: ElementType;
    forceLink?: boolean;
    LinkProps?: LinkTypeMap['props']
}

export type LinkProps = Omit<ButtonProps, 'href'> & LinkPropsBase

export function Link({ url, label, relativeLinkComponent, forceLink, LinkProps, ...other }: LinkProps) {
    const isAbsoluteUrl = url && url.startsWith("http")

    // console.log('heree relative', label, relativeLinkComponent)

    if (forceLink) {
        return relativeLinkComponent && !isAbsoluteUrl ?
            <MuiLink component={relativeLinkComponent} color="inherit" href={url || undefined} to={url || undefined} {...other as any} sx={{ fontSize: 'inherit', ...other.sx, ...LinkProps?.sx }} {...LinkProps}>{label}</MuiLink>
            :
            <MuiLink color="inherit" href={url || undefined} {...isAbsoluteUrl ? { target: '_blank' } : undefined} to={url || undefined} {...other as any} sx={{ fontSize: 'inherit', ...other.sx, ...LinkProps?.sx }} {...LinkProps}>{label}</MuiLink>
    }
    else {

        return relativeLinkComponent && !isAbsoluteUrl ?
            <Button component={relativeLinkComponent} variant="text" color="inherit" href={url || undefined} to={url || undefined} {...other} sx={{ fontSize: 'inherit', ...other.sx }}>{label}</Button>
            :
            <Button variant="text" color="inherit" href={url || undefined} {...isAbsoluteUrl ? { target: '_blank' } : undefined} {...other} sx={{ fontSize: 'inherit', ...other.sx }}>{label}</Button>
    }
}