import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import wrapPageElements from './wrap-page-element';
import wrapWithProviders from './wrap-with-providers';

export const wrapPageElement = wrapPageElements

export const wrapRootElement = wrapWithProviders
