import React from "react";
import Layout from "./src/components/layout";

export default ({ element, props }) => {
  return (
    <Layout {...props}>
      {/* <GlobalStyles
          styles={(theme) => ({
            '.html p': {
              margin: '0',
              marginBottom: '10px'
            },
            'html': {
              overflowX: 'hidden'
            },
            'body': {
              overflowX: 'hidden'
            }
          })}
        /> */}
      {element}
    </Layout>
  )
}
