import { LinkTypeMap, Menu, MenuItem, Link as MuiLink, Stack, Typography, TypographyProps } from '@mui/material';
import { ElementType, useState } from 'react';
import { Link, LinkPropsBase } from '../link';

export type LinkGroupProps = LinkPropsBase & {
    links: LinkPropsBase[] | null | undefined
    withDropdown?: boolean
    alignLeft?: boolean
    headerComponent?: string
    forceLink?: boolean
    LinkProps?: LinkTypeMap['props']
    LinkGroupItemsTypographyProps?: TypographyProps
}

function LinkGroupItems({
    links,
    alignLeft,
    relativeLinkComponent,
    forceLink,
    LinkProps,
    LinkGroupItemsTypographyProps
}: { links: LinkPropsBase[] | null | undefined, alignLeft?: boolean, relativeLinkComponent?: ElementType, forceLink?: boolean, LinkProps?: LinkTypeMap['props'], LinkGroupItemsTypographyProps?: TypographyProps }) {
    if (!links || links.length === 0) return <></>
    return <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={{ xs: 0, md: 2 }}
    >
        {links.map((link, i) => {
            const linkElement = <Link
                relativeLinkComponent={relativeLinkComponent}
                key={i}
                forceLink={forceLink}
                label={link.label}
                url={link.url}
                LinkProps={LinkProps}
                sx={!alignLeft ? {
                    width: { xs: "100%", sm: "100%", md: "inherit" }, // SEO
                    minHeight: { xs: "48px", sm: "48px", md: "inherit" }, // SEO
                    ...LinkProps?.sx
                } : { ml: 5, ...LinkProps?.sx }}
            />;
            return (
                LinkGroupItemsTypographyProps ? <Typography {...LinkGroupItemsTypographyProps} key={i}>{linkElement}</Typography> : linkElement
            )
        })}
    </Stack>
}

export function LinkGroup({
    label,
    url,
    links,
    withDropdown,
    alignLeft,
    relativeLinkComponent,
    headerComponent,
    forceLink,
    LinkProps,
    LinkGroupItemsTypographyProps
}: LinkGroupProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hasSubLinks = links && links.length > 0
    const showHeaderButton = (url || (hasSubLinks && withDropdown));

    return (
        <Stack gap={{ xs: 0, md: 2 }}>
            {label && <Typography variant={headerComponent as any} component={"div"} sx={!headerComponent ? { fontSize: "1rem", fontWeight: 700 } : undefined}>
                {showHeaderButton && <Link LinkProps={{ ...LinkProps, underline: 'none' }} forceLink={!withDropdown || forceLink} sx={{ textTransform: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', ...LinkProps?.sx }} relativeLinkComponent={!hasSubLinks ? relativeLinkComponent : undefined} url={url} label={label} onClick={withDropdown ? handleClick : undefined} />}
                {!showHeaderButton ? label : undefined}
            </Typography>}

            {links && links.length > 0 && (
                withDropdown ? (
                    <Menu
                        id={`menu-${label}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': `menu-button-${label}`,
                        }}
                    >
                        {links.map((link, i) => {
                            const isAbsoluteUrl = link.url && link.url.startsWith("http")
                            return (
                                relativeLinkComponent && !isAbsoluteUrl ?
                                    (
                                        <MuiLink component={relativeLinkComponent} underline='none' href={link.url || undefined} to={link.url || undefined} key={i}>
                                            <MenuItem onClick={handleClose}>{link.label}</MenuItem>
                                        </MuiLink>
                                    ) : (
                                        <MuiLink underline='none' href={link.url || undefined} target={isAbsoluteUrl ? '_blank' : undefined} key={i}>
                                            <MenuItem onClick={handleClose}>{link.label}</MenuItem>
                                        </MuiLink>
                                    )

                            )

                        })}
                    </Menu>
                ) : (
                    <LinkGroupItems LinkGroupItemsTypographyProps={LinkGroupItemsTypographyProps} forceLink={forceLink} LinkProps={LinkProps} links={links} alignLeft={alignLeft} relativeLinkComponent={relativeLinkComponent} />
                )
            )}
        </Stack>
    )
}
