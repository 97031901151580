import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Close, Menu } from '@mui/icons-material';
import {
  AppBar, Box,
  Container, IconButton,
  Stack, Theme, Toolbar, useTheme
} from '@mui/material';
import { LinkGroupProps, LinkList } from '@saaskit/lib-mui';
import { notPrintable } from '@saaskit/lib-styles';
import { GatsbyImage } from 'gatsby-plugin-image';

export const mobileLinkStyle = (muiTheme: Theme) => ({
  display: "block",
  color: "white",
  zIndex: 1,
  fontSize: muiTheme.typography.h3.fontSize,
  paddingTop: muiTheme.spacing(1),
  paddingBottom: muiTheme.spacing(1),
  paddingLeft: muiTheme.spacing(4),
  paddingRight: muiTheme.spacing(4),
  marginBottom: muiTheme.spacing(1),
})

export const mobileAppFloatingMenuStyle = (muiTheme: Theme) => ({
  color: "white",
  zIndex: 999,
  // fontSize: muiTheme.typography.h3.fontSize,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  pt: 10,
  pl: 1,
  pr: 1,
  position: "absolute",
  background: muiTheme.palette.primary.dark,
})

export const desktopAppBarStyle = () => ({
  // background: "transparent",
  // pt: 2,
  display: {
    xs: "none",
    sm: "none",
    md: "inherit",
    lg: "inherit",
    xl: "inherit",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const mobileAppBarStyle = () => ({
  // background: "transparent",
  // pt: 1,
  // pr: 1,
  // pl: 0,
  display: {
    xs: "inherit",
    sm: "inherit",
    md: "none",
    lg: "none",
    xl: "none",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const baseLogoHeight = 48
export const baseLogoWidth = 230

export default function Header() {
  const headerDataQuery: Queries.HeaderComponentQuery = useStaticQuery(graphql`
    query HeaderComponent {
      strapiHeader {
        logo: Logo {          
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, height: 80, quality: 100)
            }
          }
        }
        links: Links {
          url: URL
          label: Label
          links: Links {
            label: Label
            url: URL
          }
        }
      }
    }
  `)

  const NotPrintable = useMemo(() => styled('div')(notPrintable), []);

  const headerProps = useMemo(() => {
    return { ...headerDataQuery }
  }, [])

  return (
    <NotPrintable>
      <MobileHeader {...headerProps} />
      <DesktopHeader {...headerProps} />
    </NotPrintable>
  )
}
type HeaderComponentProps = Queries.HeaderComponentQuery

const DesktopHeader = ({ strapiHeader }: HeaderComponentProps) => {
  const appBarStyle = desktopAppBarStyle()
  const logo = strapiHeader?.logo?.localFile?.childImageSharp?.gatsbyImageData;

  return (
    <AppBar position="static" elevation={0} sx={{ ...appBarStyle }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/">
              <Box sx={{ display: "none" }}>Home</Box>
              {logo && <Box pt={1} pb={1}><GatsbyImage image={logo} alt="logo" loading='eager' /></Box>}
            </Link>

            <nav>
              <Stack
                direction={"column"}
                justifyContent="space-evenly"
                alignItems="center"
              >
                {strapiHeader?.links && (
                  <LinkList links={strapiHeader?.links as LinkGroupProps[]} withDropdown StackProps={{ direction: "row", gap: { md: 1, lg: 2 } }} relativeLinkComponent={Link} />
                )}
              </Stack>
            </nav>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const MobileHeader = ({ strapiHeader }: HeaderComponentProps) => {
  const theme = useTheme()

  const appBarStyle = mobileAppBarStyle()
  const openMenuStyle = mobileAppFloatingMenuStyle(theme)
  const mobileLinkStyling = mobileLinkStyle(theme)
  const [isOpen, setOpen] = useState(false)
  const logo = strapiHeader?.logo?.localFile?.childImageSharp?.gatsbyImageData;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.overflow = ""
    }
  }, [isOpen])

  return (
    <>
      <AppBar position="static" elevation={0} sx={{ ...appBarStyle }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ zIndex: 9999 }}>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Link to="/">
                <Box sx={{ display: "none" }}>Home</Box>
                {logo && <GatsbyImage image={logo} alt="logo" loading='eager' />}
              </Link>

              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                justifyContent={"flex-end"}
              >
                <div>
                  <IconButton
                    title="Toggle menu"
                    onClick={() => setOpen(!isOpen)}
                  >
                    {isOpen ? (
                      <Close sx={{ color: "white" }} />
                    ) : (
                      <Menu sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </div>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {isOpen && (
        <Box sx={openMenuStyle}>
          <nav>
            <Stack direction={'column'} sx={{ width: '100%' }} alignItems={'stretch'}>
              {strapiHeader?.links && (
                <LinkList links={strapiHeader?.links as LinkGroupProps[]} alignLeft relativeLinkComponent={Link} />
              )}
            </Stack>
          </nav>
        </Box>
      )}
    </>
  )
}
