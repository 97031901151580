import { createTheme } from '@mui/material';

// defaults - https://mui.com/material-ui/customization/breakpoints/
export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
}

let theme = createTheme({
  palette: {
    background: {
      paper: '#ebebeb',
    }
  },
  breakpoints: {
    values: breakpointValues,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "white",
        },
        html: {
          background: '#ebebeb',
          minHeight: '100vh'
        },
        // 'a, a:visited, a:hover, a:active': {
        //   color: 'inherit'
        // }
      }
    }
  }
})
// theme = responsiveFontSizes(theme);

export const MaterialUiTheme = theme;